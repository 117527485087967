//  Get the payment status for the booking
export const getBookingPaymentStatus = (total, balDue, ctaDue, symbol) => {
  if (["₹", "Rs", "INR", "Rs."].includes(symbol)) {
    if (balDue + ctaDue <= 0) {
      return "Paid";
    } else if (balDue + ctaDue < total && balDue + ctaDue > 0) {
      return "Partly paid";
    } else {
      return "Unpaid";
    }
  } else {
    if (balDue <= 0) {
      return "Paid";
    } else if (balDue < total && balDue > 0) {
      return "Partly paid";
    } else {
      return "Unpaid";
    }
  }
};

// Get the map of the number of rate plans associated with a booking
export const getMapForTheRatePlans = (ratePlans) => {
  try {
    let obj = {};
    let ratePlansArr = ratePlans.split(",");
    for (let ratePlan of ratePlansArr) {
      const formattedRatePlan = ratePlan.trim();
      if (obj.hasOwnProperty(formattedRatePlan)) {
        obj[formattedRatePlan] += 1;
      } else {
        obj[formattedRatePlan] = 1;
      }
    }
    const mapArrFromObj = Object.entries(obj).map(
      ([key, value]) => `${key} - ${value}`
    );
    const strFromArr = mapArrFromObj.join(",");
    return strFromArr;
  } catch {
    return 0;
  }
};

import { CircularProgress, TextField } from "@mui/material";
import { toast } from "material-react-toastify";
import React, { useEffect, useState } from "react";
import * as AiIcons from "react-icons/ai";
import {
  djangoHandleDataMutationRequest,
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
} from "../../../api";
import { getCardType } from "../../../Utils/cardType";
import { connect, shallowEqual, useSelector } from "react-redux";
import i18n from "../../../modules/Auth/pages/i18n";
import { useTranslation } from "react-i18next";

export function CTAActionButton({ loading, action, text }) {
  if (loading) {
    return (
      <button className="submit mr-2">
        <CircularProgress size="15px" />
      </button>
    );
  } else {
    return (
      <button className="submit w-auto mr-2" onClick={() => action()}>
        {text}
      </button>
    );
  }
}

export function ChargeAuthCardSaved({
  hotelId,
  bookingId,
  openedAddedCardData,
  invoice,
  updateInvoice,
  updateSummaryData,
  updateSummaryDataFromCache,
  closeAddedCard,
  loggedUserEmail,
  userType,
  balanceDue,
  isAdmin,
  paymentViewAccess,
  resId,
  enableRefund,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  // fields for Added cards transaction
  const [addedCardAmt, setAddedCardAmt] = useState(balanceDue);
  const [addedCardAmtError, setAddedCardAmtError] = useState(false);

  const { hotel } = useSelector(
    ({ auth }) => ({
      hotel: auth.selectedHotel,
    }),
    shallowEqual
  );

  // Added cards transaction loading
  const [addedCardLoading1, setAddedCardLoading1] = useState(false);
  const [addedCardLoading2, setAddedCardLoading2] = useState(false);
  const [addedCardLoading3, setAddedCardLoading3] = useState(false);
  const [addedCardLoading4, setAddedCardLoading4] = useState(false);
  const [errorCardCharge, setErrorCardCharge] = useState(null);
  const [errorCardHold, setErrorCardHold] = useState(null);
  const [errorCardRefund, setErrorCardRefund] = useState(null);

  useEffect(() => {
    setAddedCardAmtError(false);
  }, [addedCardAmt, bookingId]);

  // handling the Added cards transaction flow

  const handleAddedCardCharge = () => {
    if (addedCardAmt !== undefined && Number(addedCardAmt) > 0) {
      setAddedCardLoading1(true);
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/chargeCustomerCardOwn/",
        {
          hotelId: hotelId,
          trans_type: "sale",
          bookingID: bookingId,
          cust_email: invoice?.customer_email,
          name: invoice?.customer_name,
          is_emv: "NO",
          card_id: openedAddedCardData?.id.toString(),
          amount: Number(addedCardAmt),
          logged_user_email: loggedUserEmail,
          reservation_id: resId,
        }
      )
        .then((res) => {
          toast.success(`${t("Card charged successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => setAddedCardLoading1(false))
        .then((res) => closeAddedCard())
        .then((res) => setAddedCardAmt())
        // .catch((error) => {
        //   toast.error(error, {
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     autoClose: 8000,
        //     hideProgressBar: true,
        //     bodyClassName: "importantToast",
        //   });
        //   setAddedCardLoading1(false);
        // });
        .catch((err) => setErrorCardCharge(err))
        .finally(() => setAddedCardLoading1(false));
    } else {
      setAddedCardAmtError(true);
    }
  };

  const handleAddedCardRefund = () => {
    if (addedCardAmt !== undefined && Number(addedCardAmt) > 0) {
      setAddedCardLoading4(true);
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/savedCardStandaloneRefund/",
        {
          hotelId: hotelId,
          trans_type: "refund",
          bookingID: bookingId,
          cust_email: invoice?.customer_email,
          name: invoice?.customer_name,
          is_emv: "NO",
          card_id: openedAddedCardData?.id.toString(),
          amount: Number(addedCardAmt),
          logged_user_email: loggedUserEmail,
          reservation_id: resId,
        }
      )
        .then((res) => {
          toast.success(`${t("Card refunded successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => setAddedCardLoading4(false))
        .then((res) => closeAddedCard())
        .then((res) => setAddedCardAmt())
        // .catch((error) => {
        // toast.error(error, {
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   autoClose: 8000,
        //   hideProgressBar: true,
        //   bodyClassName: "importantToast",
        // });
        // });
        .catch((err) => {
          setErrorCardRefund(err);
          setAddedCardLoading4(false);
        });
    } else {
      setAddedCardAmtError(true);
    }
  };
  const handleAddedCardHold = () => {
    if (addedCardAmt !== undefined && Number(addedCardAmt) > 0) {
      setAddedCardLoading2(true);
      djangoHandleDataMutationRequest(
        "POST",
        "invoice/authorizeCustomerCardOwn/",
        {
          hotelId: hotelId,
          trans_type: "auth",
          bookingID: bookingId,
          cust_email: invoice?.customer_email,
          name: invoice?.customer_name,
          is_emv: "NO",
          card_id: openedAddedCardData?.id,
          amount: Number(addedCardAmt),
          logged_user_email: loggedUserEmail,
          reservation_id: resId,
        }
      )
        .then((res) => {
          toast.success(`${t("Card successfully authorized")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => setAddedCardLoading2(false))
        .then((res) => closeAddedCard())
        .then((res) => setAddedCardAmt())
        // .catch((error) => {
        //   toast.error(error, {
        //     closeOnClick: true,
        //     pauseOnHover: true,
        //     autoClose: 8000,
        //     hideProgressBar: true,
        //     bodyClassName: "importantToast",
        //   });
        //   setAddedCardLoading2(false);
        // });
        .catch((err) => setErrorCardHold(err))
        .finally(() => setAddedCardLoading2(false));
    } else {
      setAddedCardAmtError(true);
    }
  };
  const handleAddedCardDelete = () => {
    setAddedCardLoading3(true);
    djangoHandleDataRequests(
      `invoice/deleteCustomerCardOwn/?hotelId=${hotelId}&card_id=${openedAddedCardData?.id}&booking_id=${bookingId}&logged_user_email=${loggedUserEmail}`
    )
        .then((res) => {
          toast.success(`${t("Card deleted")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
      .then((res) => updateInvoice())
      .then((res) => setAddedCardLoading3(false))
      .then((res) => closeAddedCard())
      .catch((error) => toast.error(`${t("Failed in deleting the card.")}`));
  };

  // Display the card number
  const [displayCardNum, setDisplayCardNum] = useState(false);
  const [decryptedVal, setDecryptedVal] = useState(
    Array(12)
      .fill("X")
      .join("")
      .match(/.{1,4}/g)
      .join(" ")
  );
  function getDecryptedData(encryptedCardVal) {
    handleSpringDataMutationRequest(
      "POST",
      "core/api/v1/customer-card/get-decrypted-card-number",
      {
        cardNumber: encryptedCardVal,
      }
    )
      .then((res) => setDecryptedVal(res.card_number))
      .catch((err) => toast.error(err));
  }

  function updateCardDisplay(encryptedCardVal) {
    if (displayCardNum) {
      setDisplayCardNum(false);
    } else {
      getDecryptedData(encryptedCardVal);
      setDisplayCardNum(true);
    }
  }

  return (
    <div className="amountWrapper">
      <div id="card">
        <div className="card_header">
          <div className="card_sticker"></div>
          <div>
            {displayCardNum && (
              <img
                className="card_logo"
                src={getCardType(decryptedVal)?.imageUrl}
                alt="Card logo"
              />
            )}
          </div>
        </div>
        <div className="card_body">
          <div style={{ display: "flex", flexDirection: "row" }}>
            {!displayCardNum ? (
              <h2 id="card_creditCardNumber">{`${Array(12)
                .fill("X")
                .join("")
                .match(/.{1,4}/g)
                .join(" ")} ${openedAddedCardData?.card_last4}`}</h2>
            ) : (
              <h2 id="card_creditCardNumber">
                {decryptedVal?.match(/.{1,4}/g).join(" ")}
              </h2>
            )}
            {(isAdmin || paymentViewAccess === "YES") &&
              openedAddedCardData?.is_emv !== "YES" && (
                <div
                  style={{ cursor: "pointer", marginLeft: "5px" }}
                  onClick={() =>
                    updateCardDisplay(openedAddedCardData?.card_no)
                  }
                >
                  {!displayCardNum ? (
                    <AiIcons.AiFillEyeInvisible />
                  ) : (
                    <AiIcons.AiFillEye />
                  )}
                </div>
              )}
          </div>
        </div>
        <div className="card_footer">
          <div>
            <h5>{t("Card Holder")}</h5>
            <h3>{openedAddedCardData?.card_name}</h3>
          </div>
          <div>
            <h5>{t("Expires")}</h5>
            <h3>
              {openedAddedCardData?.card_expiry?.slice(0, 2)} /{" "}
              {openedAddedCardData?.card_expiry?.slice(2, 4)}
            </h3>
          </div>
        </div>
      </div>
      <div className="fieldWrapper mt-5">
        <TextField
          id="outlined-name"
          label={t("Enter Amount")}
          name="emv_amount"
          type={"text"}
          value={addedCardAmt}
          onChange={(e) =>
            /^[0-9.]*$/.test(e.target.value)
              ? setAddedCardAmt(e.target.value)
              : setAddedCardAmt(addedCardAmt)
          }
          error={addedCardAmtError}
          helperText={addedCardAmtError ? `${t("Enter Amount")}` : ""}
          sx={{ width: "100%" }}
        />
      </div>
      {(errorCardCharge || errorCardHold || errorCardRefund) && (
        <div className="mt-10">
          {errorCardCharge && (
            <p style={{ fontSize: "12.5px", color: "red" }}>
              {errorCardCharge}
            </p>
          )}
          {errorCardHold && (
            <p style={{ fontSize: "12.5px", color: "red" }}>{errorCardHold}</p>
          )}
          {errorCardRefund && (
            <p style={{ fontSize: "12.5px", color: "red" }}>
              {errorCardRefund}
            </p>
          )}
        </div>
      )}
      <div className="settleDuesSubmitBtns">
        <button className="cancelBtn" onClick={() => closeAddedCard()}>
          {t("Cancel")}
        </button>
        <div className="d-flex w-auto">
          <CTAActionButton
            loading={addedCardLoading1}
            action={() => handleAddedCardCharge()}
            text={"Charge"}
          />
          {enableRefund &&
            JSON.parse(
              hotel?.accessControl?.user_feature_map?.PaymentGatewayRefund
            ).read === "YES" &&
            JSON.parse(hotel?.accessControl?.user_feature_map?.HideDirectRefund)
              .read === "YES" && (
              <CTAActionButton
                loading={addedCardLoading4}
                action={() => handleAddedCardRefund()}
                text={t("Refund")}
              />
            )}
          <CTAActionButton
            loading={addedCardLoading2}
            action={() => handleAddedCardHold()}
            text={t("Authorize hold")}
          />
          <CTAActionButton
            loading={addedCardLoading3}
            action={() => handleAddedCardDelete()}
            text={t("Delete card")}
          />
        </div>
      </div>
    </div>
  );
}

export function ChargeAuthCardOTA({
  hotelId,
  bookingId,
  OTACardData,
  updateInvoice,
  updateSummaryData,
  updateSummaryDataFromCache,
  closeOTAAddedCard,
  loggedUserEmail,
  balanceDue,
  isAdmin,
  selectedLanguage,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [selectedLanguage]);
  // fields for  ota saved  cards transaction

  const [otaAddedCardAmt, setOTAAddedCardAmt] = useState(balanceDue);
  const [otaAddedCardAmtError, setOTAAddedCardAmtError] = useState(false);

  //  ota saved cards transaction loading
  const [otaAddedCardLoading1, setOTAAddedCardLoading1] = useState(false);
  const [otaAddedCardLoading2, setOTAAddedCardLoading2] = useState(false);

  useEffect(() => {
    setOTAAddedCardAmtError(false);
  }, [otaAddedCardAmt, bookingId]);

  // handling the  ota saved  cards transaction flow

  const handleOTAAddedCardCharge = () => {
    if (otaAddedCardAmt !== undefined && Number(otaAddedCardAmt) > 0) {
      setOTAAddedCardLoading1(true);
      djangoHandleDataMutationRequest("POST", "invoice/directMOTOCharge/", {
        hotelId: hotelId,
        bookingID: bookingId,
        bookingAmt: Number(otaAddedCardAmt),
        logged_user_email: loggedUserEmail,
      })
        .then((res) => {
          toast.success(`${t("OTA Card charged successfully")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => setOTAAddedCardLoading1(false))
        .then((res) => closeOTAAddedCard())
        .then((res) => setOTAAddedCardAmt())
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setOTAAddedCardLoading1(false);
        });
    } else {
      setOTAAddedCardAmtError(true);
    }
  };
  const handleOTAAddedCardHold = () => {
    if (otaAddedCardAmt !== undefined && Number(otaAddedCardAmt) > 0) {
      setOTAAddedCardLoading2(true);
      djangoHandleDataMutationRequest("POST", "invoice/directMOTOAuth/", {
        hotelId: hotelId,
        bookingID: bookingId,
        bookingAmt: Number(otaAddedCardAmt),
        logged_user_email: loggedUserEmail,
      })
        .then((res) => {
          toast.success(`${t("OTA Card successfully authorized")}`, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
        })
        .then((res) => updateSummaryDataFromCache())
        .then((res) => updateInvoice())
        .then((res) => setOTAAddedCardLoading2(false))
        .then((res) => closeOTAAddedCard())
        .then((res) => setOTAAddedCardAmt())
        .catch((error) => {
          toast.error(error, {
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 8000,
            hideProgressBar: true,
            bodyClassName: "importantToast",
          });
          setOTAAddedCardLoading2(false);
        });
    } else {
      setOTAAddedCardAmtError(true);
    }
  };

  // Display the card number
  const [displayCardNum, setDisplayCardNum] = useState(false);

  return (
    <div className="amountWrapper">
      <div id="card">
        <div className="card_header">
          <div className="card_sticker"></div>
          <div>
            <img
              className="card_logo"
              src={getCardType(OTACardData?.card_num)?.imageUrl}
              alt=" "
            />
          </div>
        </div>
        <div className="card_body">
          <div
            style={{
              position: "relative",
              display: "flex",
              flexDirection: "row",
              wordSpacing: 2,
            }}
          >
            <h2 id="card_creditCardNumber">
              {OTACardData?.card_num.match(/.{1,4}/g).join(" ")}
            </h2>
          </div>

          {/* <h4>CVV</h4> */}
        </div>
        <div className="card_footer">
          <div>
            <h5>{t("Card Holder")}</h5>
            <h3>
              {OTACardData?.card_holder_first_name}{" "}
              {OTACardData?.card_holder_last_name}
            </h3>
          </div>
          <div>
            <h5>{t("Expires")}</h5>
            <h3>
              {OTACardData?.card_exp_mm} / {OTACardData?.card_exp_yy}
            </h3>
          </div>
        </div>
      </div>
      <div className="fieldWrapper mt-5">
        <TextField
          id="outlined-name"
          label={t("Enter Amount")}
          name="emv_amount"
          type={"text"}
          value={otaAddedCardAmt}
          onChange={(e) =>
            /^[0-9.]*$/.test(e.target.value)
              ? setOTAAddedCardAmt(e.target.value)
              : setOTAAddedCardAmt(otaAddedCardAmt)
          }
          error={otaAddedCardAmtError}
          helperText={otaAddedCardAmtError ? `${t("Enter amount")}` : ""}
          sx={{ width: "100%" }}
        />
      </div>
      <div className="settleDuesSubmitBtns">
        <button className="cancelBtn">{t("Cancel")}</button>
        <div className="d-flex w-auto">
          <CTAActionButton
            loading={otaAddedCardLoading1}
            action={() => handleOTAAddedCardCharge()}
            text={t("Charge")}
          />
          <CTAActionButton
            loading={otaAddedCardLoading2}
            action={() => handleOTAAddedCardHold()}
            text={t("Authorize hold")}
          />
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { StyleSheet, Text, View, Image } from "@react-pdf/renderer";
import moment from "moment";
import { getExtension } from "../../../Utils/Utils";

const borderColor = "#E5e5e5";
const tableCell = "#F8F8F8";

// get today time
const todayDate = new Date();
const getTime = () => {
  return moment(todayDate).format("DD-MM-YYYY HH:mm A");
};

// get the total room booking amount
const getTotalRoomBookingAmt = (dashboardData) => {
  var amt = 0;
  dashboardData.forEach(
    (item) => (amt = amt + Number(item.reservation_amount))
  );
  return Number(amt).toFixed(2);
};

function CustomReportPdfDoc({
  reportColumns,
  reportData,
  reportName,
  hotelName,
  hotelLogo,
  currency,
  dateRange,
  pinnedRow,
  hotelAddress,
}) {
  const [reportDatas, setReportDatas] = useState([]);
  useEffect(() => {
    if (reportName === "Payments Report") {
      const arr = [...reportData];
      arr.map(
        (item) =>
          (item.services_fee = +item?.gateway_fee + +item?.sf_commission)
      );
      setReportDatas(arr);
    } else {
      setReportDatas(reportData);
    }
  }, [reportData]);
  // Calculating the width %
  function returnWidthOnDataType(col) {
    const { printWidth, dataType } = col;
    if (printWidth) {
      return String(printWidth);
    } else if (dataType === "varchar") {
      return "10%";
    } else if (dataType === "float") {
      return "5%";
    } else if (dataType === "integer") {
      /*less width as no decimals are there*/
      return "4%";
    } else if (dataType === "date") {
      return "5%";
    } else {
      return "10%";
    }
  }

  // Format values
  function formatValue(col, val) {
    const { field, dataType, dateFormat, valueFormatter } = col;
    if (valueFormatter) {
      const formattedVal = valueFormatter({ value: val });
      console.log("formatted val : ", formattedVal);
      return formattedVal;
    } else if (field === "pending_arrivals") {
      return val ? "Yes" : "No";
    } else {
      if (dataType) {
        if (dataType === "date" && val !== "Total") {
          if (dateFormat && val) {
            if (moment(new Date(val)).format(dateFormat) !== "Invalid date") {
              return moment(new Date(val)).format(dateFormat);
            }
            return val;
          } else if (
            moment(new Date(val)).format(dateFormat) !== "Invalid date"
          ) {
            return moment(new Date(val)).format(dateFormat);
          }
          return val;
          // else {
          //   if (
          //     moment(val, "MMM DD, YYYY HH:mm A").format("MMM DD 'YY") !==
          //     "Invalid date"
          //   ) {
          //     return moment(val, "MMM DD, YYYY HH:mm A").format("MMM DD 'YY");
          //   }
          //   return val;
          // }
        } else if (dataType === "float") {
          if (val) {
            return Number(val)?.toFixed(2);
          }
          return val;
        } else if (dataType === "integer") {
          if (val) {
            return parseInt(val);
          }
          return val;
        }
        return val;
      }
    }
    return val;
  }

  return (
    <View
      style={{
        width: "100vw",
        // height: "100vh",
        position: "relative",
        marginRight: 10,
        marginTop: 0,
        marginLeft: 10,
      }}
    >
      {/* <View style={styles.header}> */}
      <View
        style={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <View
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            marginTop: "auto",
            marginBottom: "auto",
            width: "33.33%",
          }}
        >
          <Text style={{ fontSize: "12px", marginBottom: 5 }}>
            <b>{hotelName}</b>
          </Text>
          <Text
            style={{
              position: "relative",
              color: "grey",
              fontSize: "8px",
              width: "45%",
              flexWrap: "wrap",
            }}
          >
            {hotelAddress}
          </Text>
        </View>
        <View
          style={{
            position: "relative",
            display: "flex",
            // width: "50%",
            marginTop: "auto",
            marginBottom: "auto",
            width: "33.33%",
            alignItems: "center",
          }}
        >
          {hotelLogo && getExtension(hotelLogo) ? (
            <View style={{ width: "45px", marginBottom: 5 }}>
              <Image
                style={{ width: "45px", height: "auto" }}
                src={getExtension(hotelLogo)}
              />
            </View>
          ) : (
            <Text> </Text>
          )}
        </View>
        <View
          style={{
            position: "relative",
            display: "flex",
            width: "33.33%",
            marginRight: "5px",
            flexWrap: "wrap",
            textAlign: "right",
            marginTop: "auto",
            marginBottom: "auto",
          }}
        >
          <Text style={{ fontSize: "10px", marginBottom: 5 }}>
            <b>
              {reportName} ( {moment(dateRange.startDate).format("MMM DD 'YY")}{" "}
              {"-"} {moment(dateRange.endDate).format("MMM DD 'YY")} )
            </b>
          </Text>
          <Text style={{ fontSize: "7px", marginBottom: 2, color: "grey" }}>
            Report printed on : {moment().format("MMM DD YYYY hh:mm A")}
          </Text>
          <Text style={{ fontSize: "7px", color: "grey" }}>
            Total rows : {reportDatas?.length}
          </Text>
        </View>
        {/* <View>
          <Text style={{ fontSize: "10px", marginBottom: 5 }}>{hotelName}</Text>
        </View> */}
      </View>
      <View style={styles.container}>
        {reportColumns?.map(
          (item, index) =>
            item.hide !== true && (
              <Text
                key={index}
                style={[
                  styles.field,
                  {
                    fontSize: "7px",
                    fontWeight: "bold",
                    width: returnWidthOnDataType(item),
                  },
                ]}
              >
                <b>{item.headerName}</b>
              </Text>
            )
        )}
      </View>
      <View style={styles.containerDivider}></View>
      {reportDatas?.map((item, index) => (
        <View style={styles.dataContainer} key={index}>
          {reportColumns?.map(
            (col) =>
              col?.hide !== true && (
                <Text
                  style={[
                    styles.field,
                    {
                      fontSize: "7px",
                      width: returnWidthOnDataType(col),
                      wordWrap: "break-word",
                      wordBreak: "break-all",
                    },
                  ]}
                >
                  {item[col.field] !== undefined || item[col.field] !== null
                    ? formatValue(col, item[col.field])
                    : "-"}
                </Text>
              )
          )}
        </View>
      ))}
      {pinnedRow ? (
        <View>
          <View style={styles.containerDivider}></View>
          {pinnedRow.map((item, index) => (
            <View style={styles.dataContainer} key={index}>
              {reportColumns?.map(
                (col) =>
                  col?.hide !== true && (
                    <Text
                      style={[
                        styles.field,
                        {
                          fontSize: "7px",
                          width: returnWidthOnDataType(col),
                          wordWrap: "break-word",
                          wordBreak: "break-all",
                        },
                      ]}
                    >
                      {item[col.field]
                        ? formatValue(col, item[col.field])
                        : "-"}
                    </Text>
                  )
              )}
            </View>
          ))}
        </View>
      ) : null}
    </View>
  );
}

export default CustomReportPdfDoc;

// Creating the styles for the dashboard reports fix
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    borderWidth: 1,
    borderRadius: 2,
    // backgroundColor: borderColor,
    marginLeft: 5,
    marginRight: 5,
    border: "none",
  },
  containerDivider: {
    flexDirection: "row",
    flexWrap: "wrap",
    // marginTop: 24,
    borderWidth: "2px",
    borderRadius: 2,
    backgroundColor: borderColor,
    marginLeft: 5,
    marginRight: 5,
    border: "1px solid black",
  },
  dataContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 0,
    borderWidth: 1,
    borderRadius: 2,
    // backgroundColor: tableCell,
    marginLeft: 5,
    marginRight: 5,
    border: "none",
    borderBottom: "1px solid #d9d9d9",
  },
  field: {
    display: "flex",
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
    flexWrap: "wrap",
    paddingRight: 1,
    width: "7.5%",
    paddingLeft: 4,
    marginRight: "5px",
    // wordWrap: "break-word",
    wordBreak: "break-all",
  },
  customfield: {
    display: "flex",
    position: "relative",
    paddingTop: 5,
    paddingBottom: 5,
    flexWrap: "wrap",
    paddingRight: 1,
    width: "14%",
    paddingLeft: 4,
  },
  header: {
    position: "relative",
    width: "100vw",
    // display: "flex",
    // justifyContent: "space-between",
    fontSize: "12px",
    // marginTop: "10px",
    flexDirection: "row",
    // paddingLeft: "10px",
    // paddingRight: "10px",
  },
  totalField: {
    flexDirection: "row",
    flexWrap: "wrap",
    // marginTop: 12,
    borderWidth: 1,
    borderRadius: 2,
    // backgroundColor: tableCell,
    marginLeft: 5,
    marginRight: 5,
    border: "none",
    position: "relative",
    fontSize: "8px",
    // paddingTop: "8px",
    // paddingBottom: "8px",
  },
});

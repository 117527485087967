/* eslint-disable react/style-prop-object */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  IconButton,
  Tooltip,
  tooltipClasses,
  Badge,
  Card,
  CardHeader,
  Collapse,
  CardContent,
  Typography,
  Avatar,
  Divider,
  CardActions,
  Modal,
  Box,
  CircularProgress,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import axios from "axios";
import { styled } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { Dropdown, OverlayTrigger } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { red, green } from "@mui/material/colors";
import PerfectScrollbar from "react-perfect-scrollbar";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { absoluteURL } from "../../../../../app/Utils";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { BiHelpCircle } from "react-icons/bi";
import { getAllImagesAndUrl } from "../../../../../app/pages/BookingDashboard/apis/BrandingModalApi";
import { getUserWizardRender } from "../../../../../app/pages/AccessControl/apis/AccessControlApi";
import NotificationsIcon from "@mui/icons-material/Notifications";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { updateEnquiryStatus } from "../../../../../app/pages/BookingDashboard/apis/BookingDashboardApi";
import { toast } from "material-react-toastify";
import { makeStyles } from "@material-ui/styles";
import { channelLogoMap } from "../../../../../app/pages/CalendarPage/constants/Calendar-constants";
import noBooking from "../../../../../app/pages/Folios/ViewManagePayments/not_found.svg";
import {
  configDevice,
  getCsmDetails,
  getWebNotifications,
  markAllAsRead,
  setNotificationOpened,
} from "./apis/NotificationAPI";
import { BiSupport } from "react-icons/bi";
import SupportModal from "./support/SupportModal";
import RedeemIcon from "@mui/icons-material/Redeem";
import ReferalModal from "./support/ReferalModal";
import * as AiIcons from "react-icons/ai";
import {
  djangoHandleDataRequests,
  handleSpringDataMutationRequest,
  handleSpringDataRequest,
} from "../../../../../app/api";
import FreezeExpence from "../../../../../app/Utils/FreezeExpence";
import LockIcon from "@mui/icons-material/Lock";
import { Backdrop, Fade } from "@mui/material";
import Lottie from "react-lottie";
import successAnimationData from "../../../../../app/pages/CalendarPage/Views/lotties/celebration.json";
import { useTranslation } from "react-i18next";
import i18n from "../../../../../app/modules/Auth/pages/i18n";
import GTranslateIcon from "@mui/icons-material/GTranslate";

const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const envType = process.env.REACT_APP_ENV;
const CustomBackdrop = ({ style }) => {
  return (
    <Backdrop
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.2) !important",
        ...style,
      }}
    />
  );
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#000",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: "0px 0px 15px 4px #0000001a",
    maxWidth: 450,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid transparent",
    marginRight: "1rem",
  },
}));
const useStyles = makeStyles((theme) => ({
  action: {
    marginTop: "0.5rem",
  },
}));

const MyCartDropdownHelp = (props) => {
  //function to get the calendar constant source logo
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(props.selectedLanguage);
  }, [props.selectedLanguage]);

  const [selectedLanguage, setselectedLanguage] = useState(
    localStorage.getItem("lang") ? localStorage.getItem("lang") : "en"
  );
  useEffect(() => {
    localStorage.setItem("lang", selectedLanguage);
  }, [selectedLanguage]);

  const getSource = (notification) => {
    const notifBody = JSON.parse(notification?.notif_body);
    let source = notifBody?.component_body_object?.booking_source;

    if (source === undefined) {
      const notifTitle = notifBody?.title;
      source = notifTitle.split(" ")[1];
    }

    if (source !== undefined) {
      if (channelLogoMap[source] === undefined) return "";
      return channelLogoMap[source];
    }
  };

  //CSM DETAILS for Support Modal
  const [csmData, setCsmData] = useState(null);
  useEffect(async () => {
    const groupId = props?.data?.accessControl?.hotel?.group_id;
    if (groupId && csmData == null) {
      await getCsmDetails(groupId).then((resp) => {
        if (String(resp?.status) === "200") {
          const data = resp?.data;
          setCsmData(data);
        }
      });
    }
  }, [props?.data?.accessControl?.hotel?.group_id]);

  const url = new URL(window.location.href);
  const hotelId = url.searchParams.get("hotelId");

  const [allNotifications, setAllNotifications] = useState([]);
  const [whiteLabelled, setWhiteLabelled] = useState("ENABLED");
  const [support, setSupport] = useState(false);
  const bgImage = toAbsoluteUrl("/media/misc/bg-1.jpg");
  const [cart, setCart] = useState(JSON.parse(localStorage.getItem("cart")));

  const hotelTnC = localStorage.getItem("hotel_tnc");
  /* Opens the Support Modal */
  const openSupport = () => {
    setSupport(true);
  };

  /* Closes the Support Modal */
  const hideSupport = () => {
    setSupport(false);
  };
  const [referalModal, setreferalModal] = useState(false);
  const openReferalModal = () => {
    setreferalModal(true);
  };

  /* Closes the Support Modal */
  const hideReferalModal = () => {
    setreferalModal(false);
  };

  const [translatorModal, setTranslatorModal] = useState(false);
  const openTranslatorModal = () => {
    setTranslatorModal(true);
  };

  const hideTranslatorModal = () => {
    setTranslatorModal(false);
  };

  // useEffect(() => {
  //   if(typeof window != undefined){
  //     if(localStorage.getItem('cart')){
  //         setCart(JSON.parse(localStorage.getItem('cart')));
  //     }
  //   }
  // })

  //to instantiate the firebase and set the event listener
  useEffect(() => {
    if (props?.data?.accessControl?.hotel?.hotel_email) {
      //the background message function

      const bgNotifService = async (event) => {
        //this causes to send background notification only when the pms tab is open/in background
        navigator.serviceWorker.ready.then((registration) => {
          const notificationData = event?.data?.notification;
          const notificationTitle = notificationData?.title;
          const notificationOptions = {
            body: notificationData?.body,
          };
          registration.showNotification(notificationTitle, notificationOptions);
        });
        setTimeout(function () {
          loadNotifications(); //to add it to the list
        }, 3000);
      };

      //removing any existing event listener
      navigator.serviceWorker.removeEventListener("message", bgNotifService, {
        once: true,
      });

      //To setup the firebase once opened
      const firebaseConfig = {
        apiKey: "AIzaSyBU1Yz9D8mLyTL2kFtrqXLzrLQT3o3nZic",
        authDomain: "stayflexi-web-pms.firebaseapp.com",
        databaseURL: "https://stayflexi-web-pms.firebaseio.com",
        projectId: "stayflexi-web-pms",
        storageBucket: "stayflexi-web-pms.appspot.com",
        messagingSenderId: "781784447530",
        appId: "1:781784447530:web:44e51c299d113eda002153",
        measurementId: "G-9B34J959RB",
      };
      const firebaseApp = initializeApp(firebaseConfig);
      const messaging = getMessaging(firebaseApp);

      getToken(messaging, {
        vapidKey:
          "BOpjCtL-mlNLLxsHh51OtjbGAlVajk19F3mekCK_i2Sd3iKZkCNA_3Eq2UVzn_IzFZ-yng5gujeDByC7RhrFQxg",
      })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            //console.log(currentToken);
            persistToken(currentToken);
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });

      onMessage(messaging, (payload) => {
        sendNotification(payload); //to send the booking notification
        setTimeout(function () {
          loadNotifications(); //to add it to the list
        }, 3000);
      });

      //adding the listener
      navigator.serviceWorker.addEventListener("message", bgNotifService, {
        once: true,
      });

      //when the tab is closed to remove the event listener and the service worker at the same time
      window.addEventListener("beforeunload", function (e) {
        this.navigator.serviceWorker.removeEventListener(
          "message",
          bgNotifService,
          {
            once: true,
          }
        );
        //de register the service worker registered to the given url
        if (window.navigator && navigator.serviceWorker) {
          navigator.serviceWorker
            .getRegistrations()
            .then(function (registrations) {
              for (let registration of registrations) {
                registration.unregister();
              }
            });
        }
      });
    }
  }, [props?.data?.accessControl?.hotel?.hotel_email]);

  const [accessControl, setaccessControl] = useState({});
  useEffect(async () => {
    if (
      localStorage.getItem("email") !== null &&
      localStorage.getItem("email") !== undefined
    ) {
      handleSpringDataRequest(
        `core/api/v1/accesscontrol/addUserWizardRender?email=${localStorage.getItem(
          "email"
        )}`
      )
        .then((res) => {
          setaccessControl(res?.user_feature_map);
        })
        .catch((err) => console.error(err));
      // const data = await getUserWizardRender(
      //   localStorage.getItem("hotelId"),
      //   localStorage.getItem("email")
      // );
    }
  }, [localStorage.getItem("email")]);

  const hotelName = props.data.hotelName;
  const [helpToggle, sethelpToggle] = useState(false);
  const groupEmail = props?.data?.accessControl?.hotel?.hotel_email;

  //to send a toast notification if the user is at focus on the tab
  const sendNotification = (payload) => {
    let type_of_toast = "success";
    let data = payload.data;
    let component = data.component;
    let component_body = JSON.parse(data.component_body);
    let hotel_id = component_body.hotel_id;
    let booking_id = component_body.booking_id;
    let notification = payload.notification;
    let title = notification.title;
    let notification_body = notification.body;

    toast.success(title + "\n" + notification_body);
  };

  //firebase configuration calls
  const persistToken = async (token) => {
    const url = new URL(window.location.href);
    const hotelId = url.searchParams.get("hotelId");
    await configDevice({
      hotelId: hotelId,
      token: token,
      email: groupEmail,
    });
  };

  //to get the web notifications
  const loadNotifications = async () => {
    const url = new URL(window.location.href);
    const hotelId = url.searchParams.get("hotelId");
    await getWebNotifications(hotelId).then((resp) => {
      if (String(resp?.status) == "200") {
        setAllNotifications(resp.data);
      }
    });
  };

  //to update state on notification change
  useEffect(() => {
    // console.log(
    //   "updating load notifications data length: " + allNotifications.length
    // );
  }, [allNotifications, whiteLabelled]);

  // const initilizeWidget = () => {
  //   function initFreshChat() {
  //     window.fcWidget.init({
  //       token: "15ae2c70-2954-40bc-b456-4c0b35e7b32f",
  //       host: "https://wchat.freshchat.com",
  //     });
  //   }

  //   function initialize(i, t) {
  //     let e;
  //     const data = i.getElementById(t)
  //       ? initFreshChat()
  //       : (((e = i.createElement("script")).id = t),
  //         (e.async = !0),
  //         (e.src = "https://wchat.freshchat.com/js/widget.js"),
  //         (e.onload = initFreshChat),
  //         i.head.appendChild(e));
  //   }

  //   function initiateCall() {
  //     initialize(document, "Freshchat-js-sdk");
  //   }

  //   window.addEventListener
  //     ? window.addEventListener("load", initiateCall, !1)
  //     : window.attachEvent("load", initiateCall, !1);

  //   // Copy the below lines under window.fcWidget.init inside initFreshChat function in the above snippet

  //   // To set unique user id in your system when it is available
  //   window.fcWidget.setExternalId(props.data.hotelId);

  //   window.fcWidget.user.setFirstName(
  //     props.data.hotelName + "[" + props.data.hotelId + "]"
  //   );

  //   window.fcWidget.user.setEmail(props.data.email);
  //   // window.fcWidget.open();
  //   // window.fcWidget.close();
  //   window.fcWidget.hide();
  // };
  // initilizeWidget();

  // useEffect(() => {
  //   if (helpToggle === true) {
  //     window.fcWidget.open();
  //     window.fcWidget.show();
  //   } else if (helpToggle === false) {
  //     window.fcWidget.hide();
  //     window.fcWidget.close();
  //   }
  // }, [helpToggle]);

  const [userBrandingData, setuserBrandingData] = useState();
  const userBrandingSatus = async () => {
    try {
      const data = await getAllImagesAndUrl();
      setuserBrandingData(data);
      if (data?.status === null || data?.status === undefined) {
        setWhiteLabelled("DISABLED");
      }
    } catch (e) {
      console.error(e);
    }
  };
  const classes = useStyles();

  //on close icon click or notification click
  const handleNotifClick = async (notifId) => {
    let newNotifications = allNotifications.filter(
      (notification) => notification.id != notifId
    );
    setAllNotifications(newNotifications);
    await setNotificationOpened(hotelId, notifId);
  };

  //on mark as all read href click
  const handleMarkAllasRead = async () => {
    const notifArr = [];
    for (let i = 0; i < allNotifications?.length; i++) {
      notifArr.push(allNotifications[i].id);
    }
    const data = { hotelId: hotelId };
    await markAllAsRead({ hotelId: hotelId, notificationIds: notifArr }).then(
      (resp) => {
        if (String(resp) == "success") {
          setAllNotifications([]);
        }
      }
    );
  };

  //to initial load the notifications
  useEffect(async () => {
    await userBrandingSatus();
    await loadNotifications();
  }, []);
  const [email, setemail] = useState("");
  useEffect(() => {
    setemail(localStorage.getItem("email"));
  }, [localStorage.getItem("email")]);

  //------------expence Modal------------

  const [open, setOpen] = useState(false);
  const [menu, setMenu] = useState("");
  const handleMenuModal = (menu) => {
    setMenu(menu);
    setOpen(true);
  };

  const [confirmModal, setconfirmModal] = useState(false);
  const getId = () => {
    if (menu === "Reputation management") {
      return 27;
    } else if (menu === "Expense Manager") {
      return envType === "BETA" ? 90 : 87;
    } else if (menu === "Stock management") {
      return 227;
    } else if (menu === "Shops") {
      return 16;
    }
  };

  const getImage = () => {
    if (menu === "Reputation management") {
      return "https://prodimagesbucket.blob.core.windows.net/misc/AI-powered%20Reputation%20Management%20(10).png";
    } else if (menu === "Expense Manager") {
      return "https://prodimagesbucket.blob.core.windows.net/misc/3.png";
    } else if (menu === "Stock management") {
      return "https://prodimagesbucket.blob.core.windows.net/misc/1.png";
    } else if (menu === "Shops") {
      return "https://prodimagesbucket.blob.core.windows.net/misc/2.png";
    }
  };

  const getAmount = () => {
    if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 0;
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 999;
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return 1999;
    } else if (menu === "Shops" && props?.data?.hotelCurrency === "INR") {
      return 1999;
    } else if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 0;
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 49;
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return 99;
    } else if (menu === "Shops" && props?.data?.hotelCurrency !== "INR") {
      return 99;
    }
  };
  const [paymentLoader, setpaymentLoader] = useState(false);

  const startTrial = () => {
    setpaymentLoader(true);
    const payload = {
      hotelId: hotelId,
      groupId: localStorage.getItem("mainGroupId"),
      description: `${menu} upgrade`,
      type: "ADDON",
      amount: getAmount(),
      addon_feature_id: getId(),
    };

    handleSpringDataMutationRequest(
      "POST",
      `core/api/v1/feature-upgrade/single-feature-upgrade`,
      payload
    )
      .then(() => {
        setpaymentLoader(false);
        setOpen(false);
        setconfirmModal(true);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setpaymentLoader(false);
      });
  };

  const getText = () => {
    if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>999</span> 0 /{" "}
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>3000</span> 999 /
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency === "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>5000</span> 1999 /
          {` ${t("month")}`}
        </div>
      );
    } else if (menu === "Shops" && props?.data?.hotelCurrency === "INR") {
      return (
        <div>
          {`${t("Upgrade for INR")} `}
          <span style={{ textDecoration: "line-through" }}>6000</span> 1999 /
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Reputation management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$49</span> $0 /{" "}
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Expense Manager" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$299</span> $49 /
          {` ${t("month")}`}
        </div>
      );
    } else if (
      menu === "Stock management" &&
      props?.data?.hotelCurrency !== "INR"
    ) {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$499</span> $99 /
          {` ${t("month")}`}
        </div>
      );
    } else if (menu === "Shops" && props?.data?.hotelCurrency !== "INR") {
      return (
        <div>
          {`${t("Upgrade for")} `}
          <span style={{ textDecoration: "line-through" }}>$399</span> $99 /
          {` ${t("month")}`}
        </div>
      );
    }
  };

  const successAnimation = {
    loop: true,
    autoplay: true,
    animationData: successAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div
      className="d-flex align-items-center"
      // style={{ height: "450px", overflowY: "scroll" }}
    >
      <div
        style={{
          marginTop: "1.6rem",
          marginRight: "1rem",
          marginBottom: "1rem",
          marginLeft: "1rem",
        }}
      >
        {props?.hotelType === "ISHA" ? (
          <div className="d-flex">
            {/* <Tooltip
              title={
                <Typography
                  sx={{
                    fontSize: "16px",
                  }}
                >
                  {`${t("Support")}`}
                </Typography>
              }
              arrow
            > */}
            <h4
              style={{
                color: "#36454F",
                marginBottom: "0px",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={(event) => openSupport()}
            >
              help?
            </h4>

            {/* <IconButton onClick={(event) => openSupport()}>
                <BiSupport size="25" color="#36454F" />
              </IconButton> */}
            {/* </Tooltip> */}
          </div>
        ) : (
          <>
            {groupEmail === "implementation@skypalaceinn.com" ||
            groupEmail === "reservation@maharashtratourism.gov.in" ? (
              <></>
            ) : (
              <div>
                {String(whiteLabelled) === "DISABLED" ? (
                  <div className="d-flex">
                    {/* <Tooltip
                      title={
                        <Typography
                          sx={{
                            fontSize: "16px",
                          }}
                        >
                          {`${t("Support")}`}
                        </Typography>
                      }
                      arrow
                    > */}
                    <h4
                      style={{
                        color: "#36454F",
                        marginBottom: "0px",
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={(event) => openSupport()}
                    >
                      help?
                    </h4>
                    {/* <IconButton onClick={(event) => openSupport()}>
                        <BiSupport size="25" color="#36454F" />
                      </IconButton> */}
                    {/* </Tooltip> */}
                  </div>
                ) : (
                  <>
                    {csmData?.whitelabel_support_options === "ENABLED" ? (
                      <div className="d-flex">
                        {/* <Tooltip title="Support" arrow> */}
                        <h4
                          style={{
                            color: "#36454F",
                            marginBottom: "0px",
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={(event) => openSupport()}
                        >
                          help?
                        </h4>
                        {/* <IconButton onClick={(event) => openSupport()}>
                            <BiSupport size="25" color="#1BC5BD" />
                          </IconButton> */}
                        {/* </Tooltip> */}
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            )}
          </>
        )}
        <SupportModal
          show={support}
          hide={hideSupport}
          csmData={csmData}
          currency={props?.data?.hotelCurrency}
        ></SupportModal>
      </div>
      {/* {props.hotelType === "ISHA" && (
        <div
          style={{
            marginTop: "1.2rem",
            marginRight: "1rem",
            marginBottom: "1rem",
          }}
        >
          <Tooltip title="Support" arrow>
            <IconButton onClick={(event) => openSupport()}>
              <BiSupport size="25" color="#1BC5BD" />
            </IconButton>
          </Tooltip>

          <SupportModal
            show={support}
            hide={hideSupport}
            csmData={csmData}
          ></SupportModal>
        </div>
      )} */}

      {/* {userBrandingData?.status !== "ENABLED" && (
        <Tooltip title="Help">
          <IconButton>
            <BiHelpCircle
              style={{ width: "26px", height: "26px", cursor: "pointer" }}
              onClick={() => {
                sethelpToggle(!helpToggle);
              }}
            />
          </IconButton>
        </Tooltip>
      )} */}
      {/* {props?.data?.hotelCurrency === "INR" && (
        <HtmlTooltip
          title={
            <>
              <img
                style={{ width: "100%" }}
                src={toAbsoluteUrl("/media/dashboard/refer_and_earn_.png")}
                alt=""
              />
            </>
          }
          arrow
        >
          <Button variant="light" className="mr-1">
            {" "}
            Refer {"&"} Earn
          </Button>
        </HtmlTooltip>
      )} */}

      {/* <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <FreezeExpence menu={menu} />
      </Modal> */}

      <Modal
        open={confirmModal}
        onClose={() => setconfirmModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropComponent={CustomBackdrop}
      >
        <Fade in={confirmModal}>
          <Box
            className="settleDuesModal"
            style={{
              boxShadow: "0px 0px 25px 10px #0000001a",
              height: "25rem",
              width: "70rem",
            }}
          >
            <div className="settleDuesWrapper">
              <div className="settleDuesHeader">
                <div className="settleDuesHeading">
                  {`${t("Upgrade for new features")}`}
                </div>
                <div className="close" onClick={() => setconfirmModal(false)}>
                  X
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center align-items-center w-100">
                <Lottie options={successAnimation} height={150} width={150} />
                <h1>
                  {`${t(
                    "Successfully upgraded please logout and login to access this feature"
                  )}`}
                </h1>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel, hotelType: state.auth.hotelType };
}
export default connect(mapStateToProps)(MyCartDropdownHelp);

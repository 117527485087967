import React, { useState, useEffect } from "react";
import "../TaxCategory/View/TaxCategoryModal.css";
import { connect } from "react-redux";
import { toast } from "material-react-toastify";
import { handleSpringDataRequest } from "../../api";
import CommonSlider from "../CalendarPage/Views/CommonSlider";
import {
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  Checkbox,
  TextField,
  Button,
  LinearProgress,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { makeStyles } from "@mui/styles";
import ImageUploading from "react-images-uploading";
import { format } from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  textField: {
    margin: theme.spacing(1),
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  footer: {
    margin: theme.spacing(3),
    float: "right",
  },
  linerProgress: {
    marginBottom: theme.spacing(2),
    width: "100%",
    "& > * + *": {
      marginop: theme.spacing(2),
    },
  },
}));

function IDguru({ show, hide, setselectedUserIdGuruData }) {
  const classes = useStyles();
  const [allIds, setallIds] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [searchTerm, setsearchTerm] = useState("");
  const [loader, setloader] = useState(false);

  const getAllIds = () => {
    setloader(true);
    handleSpringDataRequest(`core/api/v1/idguru/get-active-ids`)
      .then((resp) => {
        const datas = resp
          .filter((item) => item.status === "ACTIVE")
          .map((res) => ({
            id: res.id,
            data: JSON.parse(res.json_response),
          }));
        setallIds(datas);
        setloader(false);
      })
      .catch((err) => {
        toast.error(err.response.data);
        setloader(false);
      });
  };

  useEffect(() => {
    getAllIds();
  }, []);

  const getImageUrl = (url) => {
    return `data:image/png;base64,${url}`;
  };

  const handleCheckboxChange = (id) => {
    setSelectedId(id === selectedId ? null : id);
  };

  useEffect(() => {
    if (selectedId !== null && selectedId !== undefined) {
      setselectedUserIdGuruData(
        allIds.filter((item) => item.id === selectedId)[0]
      );
    } else {
      setselectedUserIdGuruData({});
    }
  }, [selectedId]);

  return (
    <div>
      <div className={classes.root}>
        <CommonSlider onBackdropClick={hide} onClose={hide} open={show}>
          <Grid
            container
            className={classes.textField}
            style={{ marginTop: "0.5rem", marginBottom: "2rem" }}
          >
            <Grid item xs={10} alignSelf="center">
              <Typography variant="h5">{"Scanned Ids"}</Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={hide}
                sx={{ float: "right", marginRight: "1rem" }}
              >
                <Close />
              </IconButton>
            </Grid>

            {loader && <LinearProgress className={classes.linerProgress} />}
            {!loader && <Divider sx={{ width: "100%", marginBottom: 2 }} />}
            <Grid
              container
              style={{
                marginTop: "2rem",
                marginBottom: "2rem",
                marginLeft: "2rem",
                marginRight: "2rem",
                display: "flex",
              }}
            >
              <TextField
                label={"Search by name"}
                placeholder={"Search by name"}
                type="search"
                size="small"
                onChange={(e) => setsearchTerm(e.target.value)}
                value={searchTerm}
                name="schedule_name"
                id="schedule_name"
                style={{
                  marginRight: "1rem",
                  width: "50%",
                }}
              />

              <Button
                className="w-25"
                variant="custom-button"
                onClick={() => {
                  if (selectedId === null || selectedId === undefined) {
                    toast.error("Please select id");
                  } else {
                    hide();
                  }
                }}
              >
                Save
              </Button>
              <Button
                style={{ marginLeft: "2rem" }}
                variant="custom-button"
                onClick={getAllIds}
              >
                Reload
              </Button>
            </Grid>
            <Grid container spacing={2} className={classes.textField}>
              <div
                className="d-flex flex-column w-100"
                style={{
                  padding: "2rem",
                  overflowY: "scroll",
                  height: "50rem",
                }}
              >
                {allIds
                  ?.filter((val) => {
                    if (searchTerm === "") {
                      return val;
                    } else if (
                      val.data.firstName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    } else if (
                      val.data.lastName
                        .toLowerCase()
                        .includes(searchTerm.toLowerCase())
                    ) {
                      return val;
                    }
                  })
                  ?.map((item) => (
                    <div key={item.id} style={{ marginBottom: "2rem" }}>
                      <div className="d-flex flex-column w-100">
                        <div className="d-flex align-items-center">
                          <Checkbox
                            value={item.id}
                            checked={selectedId === item.id}
                            onChange={() => handleCheckboxChange(item.id)}
                          />
                          <h4>{`Guest Name - ${item.data.firstName} ${item.data.lastName}`}</h4>
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <ImageUploading
                          value={[
                            {
                              data_url: getImageUrl(
                                item.data.idFrontImage_Base64
                              ),
                            },
                          ]}
                          dataURLKey="data_url"
                        >
                          {({ imageList }) => (
                            <Stack
                              className="upload__image-wrapper"
                              sx={{
                                borderRadius: "10px",
                                height: "150px",
                                backgroundColor: "#d3d3d336",
                                padding: "1rem",
                                width: "100%",
                                marginRight: "1rem",
                              }}
                            >
                              <Stack spacing="2">
                                {imageList.map((image, index) => (
                                  <Stack
                                    sx={{
                                      position: "relative",
                                      padding: "0.5rem",
                                    }}
                                    key={index}
                                  >
                                    <img
                                      src={
                                        image.data_url.includes("base64")
                                          ? image.data_url
                                          : `${image.data_url}?lastmod=${format(
                                              new Date(),
                                              "ddMMyyyyhhmmsstt"
                                            )}`
                                      }
                                      style={{
                                        height: "125px",
                                        borderRadius: "1rem",
                                        boxShadow: "md",
                                        border: "1px solid",
                                        borderColor: "gray.200",
                                      }}
                                      alt=""
                                    />
                                  </Stack>
                                ))}
                              </Stack>
                            </Stack>
                          )}
                        </ImageUploading>
                        <ImageUploading
                          value={[
                            {
                              data_url: getImageUrl(
                                item.data.idBackImage_Base64
                              ),
                            },
                          ]}
                          dataURLKey="data_url"
                        >
                          {({ imageList }) => (
                            <Stack
                              className="upload__image-wrapper"
                              sx={{
                                borderRadius: "10px",
                                height: "150px",
                                backgroundColor: "#d3d3d336",
                                padding: "1rem",
                                width: "100%",
                              }}
                            >
                              <Stack spacing="2">
                                {imageList.map((image, index) => (
                                  <Stack
                                    sx={{
                                      position: "relative",
                                      padding: "0.5rem",
                                    }}
                                    key={index}
                                  >
                                    <img
                                      src={
                                        image.data_url.includes("base64")
                                          ? image.data_url
                                          : `${image.data_url}?lastmod=${format(
                                              new Date(),
                                              "ddMMyyyyhhmmsstt"
                                            )}`
                                      }
                                      style={{
                                        height: "125px",
                                        borderRadius: "1rem",
                                        boxShadow: "md",
                                        border: "1px solid",
                                        borderColor: "gray.200",
                                      }}
                                      alt=""
                                    />
                                  </Stack>
                                ))}
                              </Stack>
                            </Stack>
                          )}
                        </ImageUploading>
                      </div>
                    </div>
                  ))}
              </div>
            </Grid>
          </Grid>
        </CommonSlider>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return { data: state.auth.selectedHotel };
}
export default connect(mapStateToProps)(IDguru);
